.user-name-link{
    color: inherit;

}
.user-name-link:hover{
    text-decoration: underline !important;
}
.project-profile-charts{
    display: flex;
    justify-content: space-around;
}

.team-project-info{
    max-height: 45vh;
    margin-top: 1rem;
    overflow-y: scroll;
}
