
.chart-user-container{
    display: flex;
    justify-content: space-around;
}

.chart-project-time{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.chart-project-time >h3{
    margin-top: 1rem;
}