.user-row-content{
   
    vertical-align: middle;
}

.user-row-content-number{

    text-align: center;
}


.user-row-btn{
    display:flex;
    align-items: center;
    justify-content: center;
}

.user-row-content-btn{
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    background-color: #187de4;
    cursor: pointer;
    border-radius: 5px;
    
}
.user-row-content-btn:hover{
    background-color: #33b5e5
    
}

.user-row-content-btn > i{
    display: block;
    height: max-content;
    color: white;
   
}

/*  User Profile */


.info-profile-timesheet{
    display: flex;
    justify-content: space-between;
    height: 480px;
    max-height: 480px;

}
.profile-user-chart{
    margin-top: 15px;
    
    background-color: #FAFAFA;
    border-radius: 5px;
}
.info-page-left-side{
    border-radius: 5px;
    width: 250px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
}    
.info-page-right-side{
    border-radius: 5px;
    width: 700px;
    min-width: 400px;
    background-color: #FAFAFA;
    overflow-y: scroll;

}    

.user-info-profile{
    width: 210px;
    margin-top: 20px;
}    

.profile-thumb-url{
    width: 100px;
    height: 100px;
}

.user-profile-thumb{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.container-profile-user-btns{
    margin-top: 30px;
    width: 250px;
    text-align: center;
}
.btn-block{
    display: block;
    cursor: pointer;
}

.btn-position{
    cursor: pointer; 
}

.search-date-button{
    background-color: transparent;
    border: none;
    outline: none;
    width: 35px;
    height: 35px;
}

.search-date-button:hover{
    background-color: #17c19118;
    border-radius: 50%;
}
.table-user-timesheet{
    overflow-y: scroll;
}

.title-box-user-timesheets{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.title-box-user-timesheets >div .label-inline{
    padding-top: 16px !important;
    padding-bottom: 16px !important;
   
}